/* src/css/HomePage.css */

.navbar-custom {
  padding: 10px 20px;
  /* background-color: #eee2ee; */
  background-color: papayawhip;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2) !important;
}


.navbar-logo {
  
  width: 140px;
  height: 44px;
}
/* src/css/HomePage.css */

/* Default height for larger devices */
.carousel-item img {
  width: 100%;
  height: 430px; /* Default height for larger screens */
  /* object-fit: cover;  */
}

/* Media query for smaller devices (mobile) */
@media (max-width: 768px) {
  .carousel-item img {
    height: 204px; /* Height for mobile devices */
  }
}

/* Media query to adjust the navbar behavior on smaller screens */
/* @media (max-width: 768px) {
  .navbar-custom {
    text-align: center;
  }

  .navbar-logo {
    margin: 0 auto;
  }
} */

/* src/css/HomePage.css */

.after-carousel-section h3 {
  font-size: 22px;
  margin-bottom: 4px;
}

.after-carousel-section p {
  font-size: 16px;
}

.after-carousel-section .btn-primary {
  padding: 10px 34px;
}


/* src/css/HomePage.css */

/* Footer styles */
/* 
.footer {
  
  background-color: aqua;
  padding: 15px 0;
  
} */

.footer-title {
  font-size: 20px; /* Larger font for AutoWealth.in */
  font-weight: bold;
  color: #000; /* Darker color for emphasis */
  margin-bottom: 5px; /* Space between title and subtitle */
}

.footer-subtitle {
  font-size: 14px; /* Smaller font for AMFI */
  color: #6c757d; /* Muted text color */
  margin: 0;
}

.footer-link {
  color: #007bff; /* Link color (blue) */
  text-decoration: none;
  display: block;
  margin-top: 10px;
}

.footer-link:hover {
  text-decoration: underline;
}

/* Layout-specific styles */
/* 
.navbar-custom .user-initials {
  font-size: 1.25rem;
  font-weight: bold;
} */

/* Hide elements for mobile */
@media (max-width: 992px) {
  .user-email {
    display: none;
  }
}
th,td{
  font-family: 'Times New Roman', Times, serif;
}
h6,p{
  font-family: 'Times New Roman', Times, serif;
}
/* Apply font-family to form labels */
label {
  font-family: 'Times New Roman', Times, serif;
}

/* Apply font-family to select boxes */
select {
  font-family: 'Times New Roman', Times, serif;
}
/* Add this to your CSS file */
.navbar-custom {
  font-family: 'Times New Roman', Times, serif; /* Apply font family to the entire navbar */
}

.custom-dropdown-item {
  font-family: 'Times New Roman', Times, serif; /* Apply font family to dropdown items */
}

.custom-login-btn {
  font-family: 'Times New Roman', Times, serif; /* Apply font family to the login button */
}

.sip-table-container {
  margin: 20px auto;
  max-width: 800px;
}

.sip-table-container h3 {
  margin-bottom: 20px;
}
.footer {
  background-color: papayawhip;
  color: black;
  box-shadow: papayawhip 0px 8px 24px;
  
}

.footer-title {
  font-weight: bold;
  font-size: 20px;
}

.footer-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.footer-link:hover {
  text-decoration: underline;
  color: #0056b3;
}

.social-media {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social-link {
  color: black;
  margin: 0 10px;
  font-size: 24px;
}

.social-link:hover {
  color: #0056b3;
}
.after-carousel-section {
  padding: 60px 20px;
  /* background-color: #f0f8ff;  */
  color: #333;
}

.section-title {
  font-family: 'Times New Roman', Times, serif;
  font-size: 32px;
  font-weight: bold;
  color: black;
  margin-bottom: 25px;
}

.section-description {
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  color: #555;
  margin-bottom: 30px;
}

.features .feature-box h5 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 22px;
  color: black;
  margin-bottom: 10px;
}

.features .feature-box p {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  color: #555;
}

.benefits h4 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 24px;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;
}

.benefits-list {
  list-style-type: none;
  padding: 0;
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  color: #555;
}

.benefits-list li {
  margin-bottom: 10px;
}

.cta-button {
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
  color: white;
}




/* HomePage.css */

html, body {
  height: 100%;
  margin: 0;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the layout takes the full height of the viewport */
}

.navbar-custom {
  position: fixed; /* Fix the navbar to the top */
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure the navbar is above other content */
}

main {
  flex: 1; /* Allow the main content to grow and fill available space */
  margin-top: 70px; /* Adjust for the navbar height */
  margin-bottom: 50px; /* Adjust for the footer height */
  padding: 20px; /* Add some padding to the main content */
}

td{
  font-size: 14px;
}

.bold-label {
  font-weight: bold;
}

.user-initials-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
  /* background-color: aqua; */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.custom-aqua-button {
  /* background-color: #00ffff !important;  */
  background-color: papayawhip;
 
  /* color: #fff !important; */
  color: black;
  /* border: none !important;  */
  /* border: 1px solid aqua !important; */
  border: 1px solid papayawhip !important;
  /* padding: 10px 20px !important; */
  border-radius: 30px !important; /* Rounded corners */
  font-size: 14px !important; /* Font size for readability */
  font-weight: bolder !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2) !important; /* Subtle box shadow */
  transition: all 0.3s ease !important; /* Smooth transition for hover effects */
}

.custom-aqua-button:hover {
  /* background-color: #00e6e6 !important;  */
  background-color: papayawhip !important; 
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.4) !important; /* Stronger box shadow on hover */
  transform: translateY(-2px) !important; /* Slight upward movement on hover */
}

.custom-aqua-button:focus {
  outline: none !important; /* Remove default focus outline */
  /* box-shadow: 0px 0px 10px rgba(0, 255, 255, 0.8) !important;  */
  box-shadow: 0px 0px 10px papayawhip !important; 
}

.custom-signup-link {
  color: #007bff; /* Bootstrap primary color */
  font-weight: bold; /* Make the text bold */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.custom-signup-link:hover {
  color: #0056b3; /* Darker shade on hover */
  text-decoration: underline; /* Add underline on hover */
}

.custom-signup-link:focus {
  outline: none; /* Remove outline when focused */
}

.custom-login-link {
  color: #007bff; /* Bootstrap primary color */
  font-weight: bold; /* Make the text bold */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.custom-login-link:hover {
  color: #0056b3; /* Darker shade on hover */
  text-decoration: underline; /* Add underline on hover */
}

.custom-login-link:focus {
  outline: none; /* Remove outline when focused */
}







