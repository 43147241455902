/* PortfolioPage.css */
h2 {
    color: #333;
  }
  
  .border {
    border: 1px solid papayawhip !important; /* Set border width, style, and color */
    /* box-shadow: 6px 8px 12px rgba(0, 0, 0, 0.1); */
    box-shadow: papayawhip 0px 8px 24px;
  
  }
  p{
    font-size: 14px;
  }
  
  
  h3 {
    /* color: #007bff; */
    color: peachpuff;
    font-family: 'Times New Roman', Times, serif;
  }
  h6{
    font-family: 'Times New Roman', Times, serif;
  }
  
  button {
    /* background-color: #007bff; */
    /* background-color: aqua; */
    background-color: papayawhip;
    
    border: none;
    color: white;
  }
  